import React, { useRef, Component } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberFormat from "react-number-format";
import * as yup from "yup";
import { useDetectOutsideClick } from "../components/useDetectOutsideClick";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

// Patterns
import PatternFirst from "../images/pattern_01.png";
import Close from "../images/icons/close.svg";

const schema = yup.object().shape({
  phone: yup.string().required(),
});

const defaultValues = {
  name: "",
  phone: "",
  date: "",
};

export default function Viewing({ contacts, forms }) {
  const content = contacts.blockContacts;
  const form = forms.formSettings;

  const popupRef = useRef();
  const [isActive, setIsActive] = useDetectOutsideClick(popupRef, false);
  const onClick = () => setIsActive(!isActive);

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const onSubmit = (data, e) => {
    fetch(
      "https://backend.roslavychi.ua/wp-json/roslavichi/v1/mail/?name=" +
        data.name +
        "&tel=" +
        data.phone +
        "&time=" +
        data.date +
        "&form=" +
        data.formTitle +
        ""
    )
      .then((response) => response.json())
      .then((data) => {
        reset();
        setIsActive(!isActive);
        trackCustomEvent({
          category: "form",
          action: "submit",
          label: "view_enroll"
        })
      });
  };

  return (
    <>
      <div className="recording">
        <h2>{form.viewingFormTitle}</h2>
        <div className="recording-content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="hidden"
              value={form.viewingFormTitle}
              {...register("formTitle", { required: true })}
            />
            <div className="input">
              <input
                type="text"
                placeholder={form.viewingFormUsername}
                {...register("name", { required: true })}
              />
            </div>
            <div className="input">
              {errors.phone && errors.phone.type === "required" && (
                <span className="errorMsg">{form.invalidPhoneMsg}</span>
              )}
              <Controller
                control={control}
                {...register("phone", {
                  required: "required",
                  pattern: {
                    value: /^\+[0-9]{2}\s\((\d+)\)\s\d{3}\s\d{2}\s\d{2}/,
                  },
                })}
                render={({ field: { onChange, name, value } }) => (
                  <NumberFormat
                    type="tel"
                    placeholder="+38 (0--) --- -- --"
                    format="+38 (0##) ### ## ##"
                    name={name}
                    value={value}
                    mask="-"
                    allowEmptyFormatting
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="input">
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange, name, value } }) => (
                  <NumberFormat
                    type="text"
                    placeholder={form.viewingFormDatetime}
                    format="##.## / ##:00"
                    name={name}
                    value={value}
                    mask={["Д", "Д", "М", "М", "-", "-"]}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="buttonBox">
              <button className="button lighter" type="submit">
                {form.modalViewingButtonText}
              </button>
            </div>
          </form>
          <dl>
            <dt>{content.scheduleListTitle}</dt>
            <dd>
              {content.scheduleList.map(({ item }, i) => (
                <div key={i}>{item}</div>
              ))}
            </dd>
            <dt>{content.contactAddressTitle}</dt>
            <dd dangerouslySetInnerHTML={{ __html: content.contactAddressText }}></dd>
            <dt>{content.contactPhonesListTitle}</dt>
            <dd>
              {content.contactPhonesList.map(({ phoneNumber }, i) => (
                <div key={i}>{phoneNumber}</div>
              ))}
            </dd>
            <dt>{content.contactEmailAdressTitle}</dt>
            <dd dangerouslySetInnerHTML={{ __html: content.contactEmailAdressText }}></dd>
          </dl>
        </div>
        <div className="pattern">
          <img src={PatternFirst} alt="Pattern" />
        </div>
      </div>
      <div
        ref={popupRef}
        className={`modalWrapper afterSend ${isActive ? "display" : ""}`}
      >
        <div className="modalContent msgBox display">
          <span className="modalClose" onClick={onClick}>
            <Close />
          </span>
          <h2>{form.modalThanksTitle}</h2>
          <p>{form.modalThanksText}</p>
          <div className="alignCenter">
            <button onClick={onClick} className="button lighter">
              {form.modalThanksButtonText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
